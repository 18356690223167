import { useMemo } from "react";
import { PaletteMode } from "@mui/material";
import {
  createTheme,
  Theme,
} from "@mui/material/styles";

import {
  BORDER_RADIUS,
  BREAKPOINTS,
  BUTTON_BORDER_RADIUS,
  TYPOGRAPHY,
} from "constants/layout";
import { useThemeMode } from "contexts/useThemeModeContext";
import purple from "@mui/material/colors/purple";
import teal from "@mui/material/colors/teal";
import { grey } from "@mui/material/colors";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    dark: PaletteColor;
    light: PaletteColor;
  }

  interface PaletteOptions {
    dark?: PaletteColorOptions;
    light?: PaletteColorOptions;
  }

  interface TypeBackground {
    dark: string;
    dividerPattern: string;
    light: string;
    nav: string;
    paperHover: string;
    cardLip: string;
    timelineConnector: string;
  }

  interface TypeText {
    dark: string;
    light: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
    dark: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    hero: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    hero?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    hero: true;
  }
}

const getDesignTokens = (mode: PaletteMode) => ({
  breakpoints: BREAKPOINTS,
  typography: TYPOGRAPHY,
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  palette: {
    mode,
    primary: {
      main: teal[100]
    },
    secondary: purple,
    background: {
      default: grey[200]
    },
  },
  components: {
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          padding: `0 ${theme.spacing(3)} ${theme.spacing(2.5)} ${theme.spacing(
            3
          )}`,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: BUTTON_BORDER_RADIUS,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: ({ theme }: { theme: Theme }) => ({
          paddingLeft: theme.spacing(0.5),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: ({ theme }: { theme: Theme }) => ({
          padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
        }),
        labelSmall: ({ theme }: { theme: Theme }) => ({
          padding: `0 ${theme.spacing(0.5)}`,
        }),
        iconSmall: ({ theme }: { theme: Theme }) => ({
          margin: 0,
          marginLeft: theme.spacing(-0.25),
        }),
        deleteIconSmall: {
          margin: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hero: "h1",
        },
      },
    },
  },
});

export const getRebodaTheme = (mode: PaletteMode) =>
  createTheme(getDesignTokens(mode));

export const useRebodaTheme = () => {
  const { themeMode: mode } = useThemeMode();

  const rebodaTheme = useMemo(
    () => getRebodaTheme(mode ?? "light"),
    [mode]
  );

  return { rebodaTheme };
};
