export const PHOTO_HEIGHT = 200; // px

export const BORDER_RADIUS = 8; // px

export const BUTTON_BORDER_RADIUS = 22; // px

export const WHITE = "hsla(0, 0%, 100%, 1)";
export const BLACK = "hsla(0, 0%, 0%, 1)";

export const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export const TYPOGRAPHY = {
  fontFamily: [
    `"Source Sans Pro"`,
    `-apple-system`,
    `system-ui`,
    `BlinkMacSystemFont`,
    `"Segoe UI"`,
    `Roboto`,
    `"Helvetica Neue"`,
    `Arial`,
    `sans-serif`,
  ].join(","),
  htmlFontSize: 16,
  fontSize: 16,
  hero: {
    lineHeight: 1.2,
    fontWeight: 300,
    fontSize: "2.5rem",
    [`@media (min-width:${BREAKPOINTS.values.sm}px)`]: {
      fontSize: "2.66rem",
    },
    [`@media (min-width:${BREAKPOINTS.values.md}px)`]: {
      fontSize: "3.5rem",
    },
  },
  h1: {
    fontSize: "2.5rem",
    lineHeight: 1.2,
    fontWeight: 300,
  },
  h2: {
    fontSize: "2rem",
    lineHeight: 1.25,
    fontWeight: 400,
  },
  h3: {
    fontSize: "1.5rem",
    lineHeight: 1.333,
    fontWeight: 400,
  },
  h4: {
    fontSize: "1.25rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  h5: {
    fontSize: "1.125rem",
    lineHeight: 1.333,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body1: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: 1.666,
    fontWeight: 400,
  },
};
