import Token from "typings/Token";
import LocalStorageTokenRepository from "./LocalStorageTokenRepository";

const TOKEN_KEY = "@@reboda-t";
const TOKEN_SEARCH_KEY = "token";

export default function LocalStorageAccessTokenRepository() {
  const local =  LocalStorageTokenRepository(TOKEN_KEY);

  const retrieve = () => {
    const searchParams = new URLSearchParams(window.location.search);
    
    if(searchParams.has(TOKEN_SEARCH_KEY)) {
      const token = new Token(searchParams.get(TOKEN_SEARCH_KEY) ?? '');
      local.save(token);
    }

    return local.retrieve();
  }

  return {
    save: local.save,
    clear: local.clear,
    retrieve
  }
}