import { ThemeMode } from "typings/ThemeMode";
import { UserPreferenceService } from "typings/UserPreferenceService";

const THEME_KEY = "@@reboda-theme-mode";

export default class StorageUserPreferenceService
  implements UserPreferenceService
{
  constructor(private storage: Storage) {}

  setThemeMode(themeMode: ThemeMode) {
    this.storage.setItem(THEME_KEY, themeMode);
  }

  getThemeMode() {
    return (this.storage.getItem(THEME_KEY) ?? "light") as ThemeMode;
  }
}
