import useTheme from "@mui/system/useTheme";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from './logo2023.png';

export function OverPage() {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: `calc(100vh)`,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container
          sx={{
            flex: "1 1 100%",
            pt: theme.spacing(3),
            pb: theme.spacing(3),
            display: "flex",
          }}
        >
          <Box
            sx={{
              flex: "1 1 100%",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className="App">
              Thanks for comming
            </div>
            <img src={logo} alt="logo"/>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}