import Token from "typings/Token";
import { TokenRepository } from "typings/TokenRepository";

export default function LocalStorageTokenRepository(
  TOKEN_KEY: string
): TokenRepository {
  function save(token: Token) {
    localStorage.setItem(TOKEN_KEY, token.value);
  }

  function retrieve() {
    const value = localStorage.getItem(TOKEN_KEY);

    if (!value) return undefined;

    return new Token(value);
  }

  function clear() {
    localStorage.removeItem(TOKEN_KEY);
  }

  return {
    save,
    retrieve,
    clear,
  };
}
