import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client";
import { TokenRepository } from "typings/TokenRepository";

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` })

export class ApolloClientProvider {

  constructor(
    private tokenRepository: TokenRepository,
  ) {}

  getApolloClient() {
    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => {
        let token = this.tokenRepository.retrieve()?.value;

        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
          },
        };
      });

      return forward(operation);
    });

  

    return new ApolloClient({
      link: from([authMiddleware, httpLink]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
      defaultOptions: {
        mutate: { errorPolicy: "all" },
      },
    });
  }
}