import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { useResolve } from "react-jpex";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ThemeMode } from "typings/ThemeMode";
import { UserPreferenceService } from "typings/UserPreferenceService";

type ThemeModeContextType = {
  themeMode?: ThemeMode;
  toggleThemeMode: () => void;
};

export const defaultThemeModeContext: ThemeModeContextType = {
  themeMode: "light",
  toggleThemeMode: () => {},
};

const ThemeModeContext = createContext(defaultThemeModeContext);

ThemeModeContext.displayName = "Reboda::ThemeMode";

export const useThemeMode = () => useContext(ThemeModeContext);

type ThemeModeContextProviderProps = {
  children: ReactNode;
};
export const ThemeModeContextProvider = ({
  children,
}: ThemeModeContextProviderProps) => {
  const localPreferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService"
  );

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  const localThemeMode = localPreferenceService.getThemeMode();

  const initialMode = localThemeMode || (prefersDarkMode ? "dark" : "light");

  const [themeMode, setThemeMode] = useState<ThemeMode>(initialMode);

  const value = useMemo(
    () => ({
      themeMode,
      toggleThemeMode: () => {
        const newMode = themeMode === "light" ? "dark" : "light";
        localPreferenceService.setThemeMode(newMode);
        setThemeMode(newMode);
      },
    }),
    [themeMode, setThemeMode, localPreferenceService]
  );

  return (
    <ThemeModeContext.Provider value={value}>
      {children}
    </ThemeModeContext.Provider>
  );
};
