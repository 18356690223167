import './App.css';
import { Routes } from 'react-router-dom';
import { Routes as AppRoutes } from 'pages';
import { ThemeProvider } from '@mui/material/styles';
import { useRebodaTheme } from 'hooks/useRebodaTheme';

export default function App() {
  const { rebodaTheme } = useRebodaTheme();

  return (
    <ThemeProvider theme={rebodaTheme}>
        <Routes>
          {AppRoutes()}
        </Routes>
    </ThemeProvider>
  );
}
